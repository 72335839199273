$foreground: #333533;
$background: #202020;
$primary: #FFEE32;
$secondary: #D6D6D6;
$tertiary: #FFD100;

$theme-colors: (
    ) !default;
$theme-colors: map-merge(("primary": $primary, // Primary is required to make bootstrap play nicely.
            "foreground": $foreground,
            "background": $background,
            "secondary": $secondary,
            "tertiary": $tertiary,
        ), $theme-colors
);

@import "bootstrap/scss/bootstrap.scss";